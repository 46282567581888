<template>
    <div class="baseMain">
        <div class="baseSearch clearfloat">
            <div class="baseSearchOne">
                <div class="baseSearchOneLeft">
                    <span>姓名：</span>
                </div>
                <div class="baseSearchOneRight">
                    <el-input v-model="searchForm.user_name" placeholder="请输入内容" clearable></el-input>
                </div>
            </div>
            <div class="baseSearchOne">
                <div class="baseSearchOneLeft">
                    <span>身份证号：</span>
                </div>
                <div class="baseSearchOneRight">
                    <el-input v-model="searchForm.user_id_card" placeholder="请输入内容" clearable></el-input>
                </div>
            </div>
            <div class="baseSearchOne">
                <div class="baseSearchOneLeft">
                    <span>退款日期：</span>
                </div>
                <div class="baseSearchOneRight2">
                    <el-date-picker v-model="searchForm.time" type="daterange" unlink-panels
                                    range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期"
                                    value-format="YYYY-MM-DD"></el-date-picker>
                </div>
            </div>
            <!--<div class="baseSearchOne">-->
            <!--<div class="baseSearchOneLeft">-->
            <!--<span>组织部门：</span>-->
            <!--</div>-->
            <!--<div class="baseSearchOneRight">-->
            <!--<el-cascader v-model="searchForm.org_id" :options="org_id_list" :props="props"-->
            <!--clearable></el-cascader>-->
            <!--</div>-->
            <!--</div>-->
            <!--<div class="baseSearchOne">-->
            <!--<div class="baseSearchOneLeft">-->
            <!--<span>缴费项目：</span>-->
            <!--</div>-->
            <!--<div class="baseSearchOneRight">-->
            <!--<el-select v-model="searchForm.project_id" clearable>-->
            <!--<el-option v-for="item in project_id_list" :key="item.value" :label="item.label"-->
            <!--:value="item.value"></el-option>-->
            <!--</el-select>-->
            <!--</div>-->
            <!--</div>-->
            <!--<div class="baseSearchOne">-->
            <!--<div class="baseSearchOneLeft">-->
            <!--<span>退款状态：</span>-->
            <!--</div>-->
            <!--<div class="baseSearchOneRight">-->
            <!--<el-select v-model="searchForm.refund_status" clearable>-->
            <!--<el-option v-for="item in refund_status_list" :key="item.value" :label="item.label"-->
            <!--:value="item.value"></el-option>-->
            <!--</el-select>-->
            <!--</div>-->
            <!--</div>-->
            <!--<div class="baseSearchOne">-->
            <!--<div class="baseSearchOneLeft">-->
            <!--<span>姓名：</span>-->
            <!--</div>-->
            <!--<div class="baseSearchOneRight">-->
            <!--<el-input v-model="searchForm.input" placeholder="请输入内容"></el-input>-->
            <!--</div>-->
            <!--</div>-->
            <!--<div class="baseSearchOne">-->
            <!--<div class="baseSearchOneLeft">-->
            <!--<span>学工号：</span>-->
            <!--</div>-->
            <!--<div class="baseSearchOneRight">-->
            <!--<el-input v-model="searchForm.card_number" placeholder="请输入内容" clearable></el-input>-->
            <!--</div>-->
            <!--</div>-->
            <!--<div class="baseSearchOne">-->
            <!--<div class="baseSearchOneLeft">-->
            <!--<span>姓名：</span>-->
            <!--</div>-->
            <!--<div class="baseSearchOneRight">-->
            <!--<el-input v-model="searchForm.user_name" placeholder="请输入内容" clearable></el-input>-->
            <!--</div>-->
            <!--</div>-->
            <div class="baseSearchOne">
                <el-button type="primary" style="margin-left: 20px;" @click="search">搜索</el-button>
                <el-button @click="cleanAll">清空</el-button>
            </div>
        </div>
        <div class="baseOperate clearfloat">
            <div class="baseOperateOne">
                <el-button type="primary" @click="openExport" v-if="authorityStatus.refundExport.show">导出全部</el-button>
            </div>
        </div>
        <div class="baseTable" v-loading="tableLoading">
            <publicTable :publicTableOtherHeight="searchHeight" :publicTableData="tableData">
                <el-table-column prop="r_order_no" label="业务订单号"></el-table-column>
                <el-table-column prop="user_name" label="姓名"></el-table-column>
                <el-table-column prop="user_id_card" label="身份证号"></el-table-column>
                <el-table-column prop="project_name" label="缴费项目"></el-table-column>
                <el-table-column prop="refund_price" label="退款金额"></el-table-column>
                <el-table-column prop="refund_type_name" label="退款方式"></el-table-column>
                <el-table-column prop="refund_status_name" label="退款状态"></el-table-column>
                <el-table-column prop="created_at" label="退款时间" width="110"></el-table-column>
                <el-table-column prop="op_user_name" label="操作人"></el-table-column>
                <el-table-column label="操作">
                    <template #default="scope">
                        <!--<el-link class="tableButton" type="danger" @click.prevent="openRefund(scope.row)">-->
                        <!--重新申请-->
                        <!--</el-link>-->
                        <el-link class="tableButton" type="primary" @click.prevent="openReceipt(scope.row)"
                                 v-if="scope.row.refund_status == 1 && authorityStatus.refundDown.show">
                            下载凭证
                        </el-link>
                    </template>
                </el-table-column>
            </publicTable>
        </div>
        <div class="basePage" style="margin-top: 4px;">
            <publicPage ref="publicPageUserStudent" :publicPageTotal="{total:total}"
                        @pageChange="getTableData"></publicPage>
        </div>
        <el-dialog title="下载收据" v-model="receiptVisible" width="1600px">
            <div style="width: 1440px;box-sizing: border-box;padding-bottom: 10px;margin: 0 auto;">
                <el-radio v-model="showIdCard" :label="true">身份证号显示</el-radio>
                <el-radio v-model="showIdCard" :label="false">身份证号隐藏</el-radio>
            </div>
            <div class="receipt" ref="imageTofile">
                <div class="receiptTitle">
                    <span>{{showMsg.school_name}}报名退款电子凭证</span>
                </div>
                <div class="receiptSubtitle">
                    <div class="receiptSubtitleLeft">
                        <span>退款订单号：{{selMsg.r_order_no}}</span>
                    </div>
                    <div class="receiptSubtitleRight">
                        <span>退款时间：{{selMsg.created_at}}</span>
                    </div>
                </div>
                <div class="receiptMain receiptBT receiptBL clearfloat">
                    <div class="receiptMainLeft">
                        <div class="receiptMainLeftOne receiptBR receiptBB">
                            <div class="receiptMainLeftMsg">
                                <div class="receiptMainLeftMsgTop">
                                    <span>姓名</span>
                                </div>
                                <div class="receiptMainLeftMsgBottom">
                                    <span>{{selMsg.user_name}}</span>
                                    <span v-if="showIdCard">&nbsp;({{selMsg.newIdCard}})</span>
                                </div>
                            </div>
                        </div>
                        <div class="receiptMainLeftOne clearfloat">
                            <div class="receiptMainLeftOneIn receiptBR receiptBB">
                                <div class="receiptMainLeftMsg">
                                    <div class="receiptMainLeftMsgTop">
                                        <span>学工号</span>
                                    </div>
                                    <div class="receiptMainLeftMsgBottom">
                                        <span>暂无</span>
                                    </div>
                                </div>
                            </div>
                            <div class="receiptMainLeftOneIn receiptBR receiptBB">
                                <div class="receiptMainLeftMsg">
                                    <div class="receiptMainLeftMsgTop">
                                        <span>退款方式</span>
                                    </div>
                                    <div class="receiptMainLeftMsgBottom">
                                        <span>{{selMsg.refund_type_name }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="receiptMainLeftOne clearfloat">
                            <div class="receiptMainLeftOneIn receiptBR receiptBB">
                                <div class="receiptMainLeftMsg">
                                    <div class="receiptMainLeftMsgTop">
                                        <span>组织机构</span>
                                    </div>
                                    <div class="receiptMainLeftMsgBottom">
                                        <span>{{showMsg.school_name}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="receiptMainLeftOneIn receiptBR receiptBB">
                                <div class="receiptMainLeftMsg">
                                    <div class="receiptMainLeftMsgTop">
                                        <span>学年学期</span>
                                    </div>
                                    <div class="receiptMainLeftMsgBottom">
                                        <span>无</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="receiptMainLeft">
                        <div class="receiptMainLeftOne2 receiptBR receiptBB">
                            <div class="receiptMainLeftMsg">
                                <div class="receiptMainLeftMsgTop" style="padding-top: 30px;">
                                    <span>缴费项目</span>
                                </div>
                                <div class="receiptMainLeftMsgBottom" style="padding-top: 10px;">
                                    <span>{{selMsg.project_name}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="receiptMainLeftOne clearfloat">
                            <div class="receiptMainLeftOneIn receiptBR receiptBB">
                                <div class="receiptMainLeftMsg">
                                    <div class="receiptMainLeftMsgTop">
                                        <span>已缴总金额</span>
                                    </div>
                                    <div class="receiptMainLeftMsgBottom">
                                        <span>￥{{selMsg.refund_price}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="receiptMainLeftOneIn receiptBR receiptBB">
                                <div class="receiptMainLeftMsg">
                                    <div class="receiptMainLeftMsgTop">
                                        <span>退款金额</span>
                                    </div>
                                    <div class="receiptMainLeftMsgBottom">
                                        <span>￥{{selMsg.refund_price}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="receiptMainLeftOne3 receiptBL receiptBR receiptBB">
                    <span>人民币（大写）：{{selMsg.daxie}}</span>
                </div>
                <div class="receiptMainLeftOne3">
                    <span>收款单位（盖章）：{{showMsg.school_name}}</span>
                    <span style="float: right;">退款操作人：{{selMsg.op_user_name}}</span>
                </div>
                <div class="receiptMainLeftOne3">
                    <span>注：本电子收据仅作为线上缴费确认依据，不作为报销凭证</span>
                </div>
                <img class="receiptMainZhang" :src="showMsg.receipt_seal">
            </div>
            <template #footer>
                <div class="dialog-footer">
                    <el-button type="primary" @click="downImage">下 载</el-button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<script>

    import publicPage from '../../components/publicPage.vue'

    import publicTable from '../../components/publicTable.vue'

    import XLSX from 'xlsx'

    import html2canvas from 'html2canvas'

    import {businessGet} from '../../api/business.js'

    import Global from '../../Global.js'

    import {utilsGetOptionsList, utilsGetAuthority, utilsExportExcel, utilsIdcardHide, utilsDX} from '../../utils/utils.js'

    export default {
        name: "payRefund",
        components: {
            publicPage,
            publicTable
        },
        data() {
            return {
                options: [
                    {
                        value: '选项1',
                        label: '黄金糕',
                    },
                    {
                        value: '选项2',
                        label: '双皮奶',
                    },
                    {
                        value: '选项3',
                        label: '蚵仔煎',
                    },
                    {
                        value: '选项4',
                        label: '龙须面',
                    },
                    {
                        value: '选项5',
                        label: '北京烤鸭',
                    },
                ],
                org_id_list: [],
                props: {
                    value: 'id',
                    label: 'name',
                    children: 'son',
                    checkStrictly: true
                },
                project_id_list: [],
                refund_status_list: [],
                searchForm: {
                    user_name: '',
                    user_id_card: '',
                    time: [],
                },
                searchHeight: 0,
                tableLoading: false,
                tableData: [],
                total: 0,
                selMsg: null,
                receiptVisible: false,
                showMsg: {
                    school_name: '',
                    receipt_seal: '',
                },
                showIdCard: true,
                authorityStatus: {
                    refundDown: {
                        method: 'post',
                        url: 'baomingtuikuanpingzheng',
                        show: false,
                    },
                    refundExport: {
                        method: 'get',
                        url: '/admin/v1/sign/refund_export',
                        show: false,
                    }
                }
            }
        },
        mounted() {
            utilsGetAuthority(this.authorityStatus);
            this.userInfo = JSON.parse(localStorage.getItem(Global.GlobalLocalName2));
            this.showMsg.school_name = this.userInfo.school_name;
            this.getMsg();
            this.getTreeData();
            this.get_project_id_list();
            this.$nextTick(() => {
                let baseSearchDom = document.querySelector('.baseSearch');
                let baseOperateDom = document.querySelector('.baseOperate');
                let searchHeight = baseSearchDom.offsetHeight + baseOperateDom.offsetHeight;
                this.searchHeight = searchHeight;
                this.cleanAll();
            });
            this.refund_status_list = utilsGetOptionsList('Global_refund_status_obj');
        },
        methods: {
            get_project_id_list() {
                let url = '/admin/v1/projects?page=1&page_pre=500';
                businessGet(url).then(res => {
                    if (res.data.status == 200) {
                        let newArray = [];
                        res.data.data.list.forEach(item => {
                            let a = {
                                value: item.id,
                                label: item.name
                            }
                            newArray.push(a);
                        })
                        this.project_id_list = newArray;
                    }
                    else {
                        this.$message({
                            showClose: true,
                            message: res.data.message,
                            type: 'warning'
                        });
                    }
                })
            },
            getTreeData() {
                let url = '/admin/v1/organization?type=1';
                businessGet(url).then(res => {
                    if (res.data.status == 200) {
                        this.org_id_list = res.data.data;
                    }
                })
            },
            openExport() {
                let url = '/admin/v1/sign/refund?is_export=1';
                for (let k in this.searchForm) {
                    if (this.searchForm[k]) {
                        if (k == 'time') {
                            if (this.searchForm[k][0] && this.searchForm[k][1]) {
                                url = url + '&' + k + '=' + this.searchForm[k][0] + ' 00:00:00 - ' + this.searchForm[k][1] + ' 23:59:59';
                            }
                        }
                        else {
                            url = url + '&' + k + '=' + this.searchForm[k];
                        }
                    }
                }
                utilsExportExcel(url)
            },
            base64ToBlob(code) {
                let parts = code.split(';base64,');
                let contentType = parts[0].split(':')[1];
                let raw = window.atob(parts[1]);
                let rawLength = raw.length;
                let uInt8Array = new Uint8Array(rawLength);
                for (let i = 0; i < rawLength; ++i) {
                    uInt8Array[i] = raw.charCodeAt(i);
                }
                return new Blob([uInt8Array], {type: contentType});
            },
            downloadFile(fileName, content) {
                let aLink = document.createElement('a');
                let blob = this.base64ToBlob(content); //new Blob([content]);
                let evt = document.createEvent("HTMLEvents");
                evt.initEvent("click", true, true);//initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
                aLink.download = fileName;
                aLink.href = URL.createObjectURL(blob);
                aLink.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));//兼容火狐
            },
            downImage() {
                // 第一个参数是需要生成截图的元素,第二个是自己需要配置的参数,宽高等
                const opts = {
                    useCORS: true,
                    backgroundColor: null
                }
                html2canvas(this.$refs.imageTofile, opts).then((canvas) => {
                    let url = canvas.toDataURL('image/png');
                    this.downloadFile('退款收据.png', url);
                })
            },
            openReceipt(val) {
                this.selMsg = val;
                this.selMsg.newIdCard = utilsIdcardHide(this.selMsg.user_id_card)
                this.selMsg.daxie = utilsDX(this.selMsg.refund_price)
                this.receiptVisible = true;
            },
            exportExcel() {
                this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    //数据表格
                    let exportList = [];
                    var aoa = [
                        ['支付宝账号', '支付宝账号姓名', '转账金额', '转账人', '转账时间', '转账状态', '备注'],
                    ];
                    let dayinName = ['receiverId', 'receiverName', 'transAmount', 'operaterName', 'fuTransDate', 'statusName', 'remark']
                    exportList.forEach(item => {
                        let a = []
                        dayinName.forEach(item2 => {
                            a.push(item[item2])
                        })
                        aoa.push(a)
                    })
                    //创建book
                    var wb = XLSX.utils.book_new();
                    var ws = XLSX.utils.aoa_to_sheet(aoa);
                    //设置列宽
                    ws['!cols'] = [
                        {width: 20},
                        {width: 20},
                        {width: 20},
                        {width: 20},
                        {width: 20},
                        {width: 20},
                    ];
                    XLSX.utils.book_append_sheet(wb, ws, "sheet1");
                    //输出
                    let exportName = "转账记录.xlsx"
                    XLSX.writeFile(wb, exportName);
                }).catch(() => {
                })
            },
            openRefund() {
                this.dialogVisible = true;
                this.$confirm('此操作将重新申请, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {

                }).catch(() => {

                })
            },
            cleanAll() {
                this.searchForm.user_name = '';
                this.searchForm.user_id_card = '';
                this.searchForm.time = [];
                this.search();
            },
            search() {
                this.$refs.publicPageUserStudent.currentPage = 1;
                this.getTableData();
            },
            getTableData() {
                let url = '/admin/v1/sign/refund?page=' + this.$refs.publicPageUserStudent.currentPage + '&page_pre=' + this.$refs.publicPageUserStudent.pageSize;
                for (let k in this.searchForm) {
                    if (this.searchForm[k]) {
                        if (k == 'time') {
                            if (this.searchForm[k][0] && this.searchForm[k][1]) {
                                url = url + '&' + k + '=' + this.searchForm[k][0] + ' 00:00:00 - ' + this.searchForm[k][1] + ' 23:59:59';
                            }
                        }
                        else {
                            url = url + '&' + k + '=' + this.searchForm[k];
                        }
                    }
                }
                this.tableLoading = true;
                businessGet(url).then(res => {
                    this.tableLoading = false;
                    if (res.data.status == 200) {
                        this.total = res.data.data.count;
                        let tableData = res.data.data.list;
                        tableData.forEach(item => {
                            item.refund_type_name = '微信';
                            item.refund_status_name = Global.Global_refund_status_obj[item.refund_status];
                        })
                        this.tableData = tableData;
                    }
                    else {
                        this.$message({
                            showClose: true,
                            message: res.data.message,
                            type: 'warning'
                        });
                    }
                })
            },
            getMsg() {
                let url = '/admin/v1/base_config/' + this.userInfo.school_code;
                businessGet(url).then(res => {
                    if (res.data.status == 200) {
                        if (res.data.data.receipt_seal) {
                            this.showMsg.receipt_seal = res.data.data.receipt_seal;
                        }
                    }
                    else {
                        this.$message({
                            showClose: true,
                            message: res.data.message,
                            type: 'warning'
                        });
                    }
                })
            }
        }
    }
</script>

<style scoped>

    @import "../../assets/css/base.css";

    .receiptBT {
        border-top: 1px solid #303133;
    }

    .receiptBL {
        border-left: 1px solid #303133;
    }

    .receiptBR {
        border-right: 1px solid #303133;
    }

    .receiptBB {
        border-Bottom: 1px solid #303133;
    }

    .receipt {
        width: 1440px;
        height: 712px;
        box-sizing: border-box;
        padding: 20px 10px;
        border: 1px solid #eee;
        margin: 0 auto;
        position: relative;
        background-color: #fff;
    }

    .receiptTitle {
        width: 100%;
        height: 50px;
        line-height: 50px;
        text-align: center;
        font-weight: bold;
        font-size: 20px;
        color: #303133;
    }

    .receiptSubtitle {
        width: 100%;
        height: 34px;
        line-height: 34px;
        font-size: 12px;
        color: #303133;
    }

    .receiptSubtitleLeft {
        height: 100%;
        float: left;
    }

    .receiptSubtitleRight {
        height: 100%;
        float: right;
    }

    .receiptMain {
        width: 100%;
        box-sizing: border-box;
    }

    .receiptMainLeft {
        width: 50%;
        box-sizing: border-box;
        float: left;
    }

    .receiptMainLeftOne {
        width: 100%;
        height: 58px;
        box-sizing: border-box;
    }

    .receiptMainLeftOneIn {
        width: 50%;
        height: 100%;
        box-sizing: border-box;
        float: left;
    }

    .receiptMainLeftMsg {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
    }

    .receiptMainLeftMsgTop {
        width: 100%;
        height: 50%;
        box-sizing: border-box;
        padding: 8px 10px 0 10px;
        color: #606266;
        font-size: 12px;
        text-align: left;
    }

    .receiptMainLeftMsgBottom {
        width: 100%;
        height: 50%;
        box-sizing: border-box;
        padding: 0 10px;
        color: #000000;
        font-size: 12px;
        text-align: left;
        font-weight: bold;
    }

    .receiptMainLeftOne2 {
        width: 100%;
        height: 116px;
        box-sizing: border-box;
    }

    .receiptMainLeftOne3 {
        width: 100%;
        height: 40px;
        box-sizing: border-box;
        line-height: 40px;
        text-align: left;
        color: #606266;
        padding: 0 16px;
        font-size: 14px;
    }

    .receiptMainZhang {
        width: 200px;
        height: 200px;
        box-sizing: border-box;
        object-fit: contain;
        position: absolute;
        top: 234px;
        left: 68px;
    }

</style>
